import React, { useEffect, useState } from 'react';
import { RESERVATION_TYPE } from '../../consts/reservation.consts';
import RenderMobileInactive from './render-mobile-inactive';
import { PREVIEW_TYPES } from '../../consts/images.const';
import SpotBreadcrumbs from '../../components/spot-breadcrumbs';
import RenderHostInfo from './render-host-info';
import RenderInactive from './render-inactive';
import SniffCarouselClient from '../../components/carousel';
import RequestMorePhotos from '../../components/request-more-photos';
import SniffCarouselSSR from '../../components/carousel/carousel-ssr';
import RenderSubInfo from './render-sub-info';
import ListingHead from '../../components/listing-head';
import SniffButton from '../../components/sniff-button';

const RenderHeadMobile = ({
    spot,
    showDisTop,
    setMobilePhotoGallery,
    handleSwipeMobile,
    mobilePhotoCount,
    listBread,
    goToReviews,
    goToHost,
    handleBook,
    handleSniffpassBadge,
    handleMembershipBadge,
    userDetails,
}) => {
    const [Carousel, setCarousel] = useState(
        <SniffCarouselSSR
            {...{
                item: spot,
                useEffect,
                useState,
                lazy: 'eager',
                showArrow: false,
                server: true,
            }}
            onClick={() => setMobilePhotoGallery(true)}
            spotGallery
        />
    );

    useEffect(() => {
        setCarousel(
            <SniffCarouselClient
                images={spot.spotPhotos}
                type={PREVIEW_TYPES.VIEW}
                disableArrow
                handleSwipeMobile={handleSwipeMobile}
                mobilePhotoCount={mobilePhotoCount}
                onClick={() => setMobilePhotoGallery(true)}
                spotHost={spot.host}
                spotId={spot.id}
                title={spot.title}
                state={spot.state}
                city={spot.city}
                lazy="eager"
                spotGallery
            />
        );
    }, []);

    return (
        <div className="head">
            <div className="d-block w-100">
                {(!spot.live || spot.host.id == 0) && (
                    <>
                        {showDisTop && <RenderMobileInactive spot={spot} />}
                        <RenderInactive {...{ spot, useState }} />
                    </>
                )}
                <div className="mobile-carousel">
                    {spot.spotPhotos && spot.spotPhotos.length > 0 ? (
                        <div className={`spot-main-photo ${useState ? 'carou-ssr' : ''}`}>{Carousel}</div>
                    ) : (
                        <div className="spot-main-photo placeholder-photo">
                            <RequestMorePhotos id={spot.id} />
                        </div>
                    )}
                </div>
                <SpotBreadcrumbs SSR {...{ listBread }} />
                <div className="info w-100">
                    <ListingHead
                        size={spot.size}
                        fncd={spot.enclosureType}
                        title={spot.title}
                        city={spot.city}
                        state={spot.state}
                        sizeUnits={spot.sizeUnits}
                        distanceFrom={spot.distanceFrom}
                        userDetails={userDetails}
                    />
                </div>
                <RenderSubInfo
                    spot={spot}
                    handleGoTo={goToReviews}
                    handleSniffpassBadge={handleSniffpassBadge}
                    handleMembershipBadge={handleMembershipBadge}
                />
                <RenderHostInfo spot={spot} handleGoTo={goToHost} useState={useState} />
                <div className="container mt-2">
                    <SniffButton
                        className="mb-2"
                        color="secondary"
                        size="lg"
                        disabled={!spot.live || spot.host.id == 0}
                        onClick={() => handleBook(RESERVATION_TYPE.CALENDAR)}
                    >
                        Find an available time
                    </SniffButton>
                    <hr />
                </div>
            </div>
        </div>
    );
};

export default RenderHeadMobile;
